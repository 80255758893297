import { Select, Typography } from 'antd';
import { useEffect } from 'react';
import './styles.css';

type Props = {
	label: string;
	isLoading: boolean;
	name: string;
	options: {
		value: string;
		label: string;
	}[];
	getData?: () => void;
	onChange: (value: string, option: string) => void;
	fetchData?: any;
	value?: string | null;
};

export const LabelField = ({
	getData,
	label,
	options,
	onChange,
	name,
	isLoading,
	fetchData,
	value,
}: Props) => {
	useEffect(() => {
		if (getData && fetchData) {
			getData();
		}
	}, [fetchData]);
	return (
		<div className='container-field'>
			<Typography.Text strong>{label}:</Typography.Text>
			<Select
				loading={isLoading}
				allowClear
				showSearch
				placeholder='Выберите значение'
				options={options}
				onChange={value => onChange(value, name)}
				value={value}
			></Select>
		</div>
	);
};
