import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDatabaseSetting } from './type';

interface ISettingsSliceState {
	isLoading: boolean;
	databaseSetting: IDatabaseSetting;
}

const initialState: ISettingsSliceState = {
	isLoading: false,
	databaseSetting: {
		db: null,
		schema: null,
		tableEvents: null,
		tableColumnPossibleValues: null,
		tableEventPropertiesPossibleValues: null,
	},
};

export const settingsSlice = createSlice({
	name: 'settingsSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): ISettingsSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		changeField: (
			state,
			action: PayloadAction<{ settingKey: string; fieldKey: string; valueFieldKey: string }>,
		): ISettingsSliceState => {
			const { settingKey, fieldKey, valueFieldKey } = action.payload;
			if (settingKey === 'databaseSetting') {
				if (fieldKey === 'db') {
					return {
						...state,
						[settingKey]: { ...initialState.databaseSetting, [fieldKey]: valueFieldKey },
					};
				} else if (fieldKey === 'schema') {
					return {
						...state,
						[settingKey]: {
							...state[settingKey],
							[fieldKey]: valueFieldKey,
							tableEvents: null,
							tableColumnPossibleValues: null,
						},
					};
				}
				return { ...state, [settingKey]: { ...state[settingKey], [fieldKey]: valueFieldKey } };
			}
			return state;
		},
		initDatabaseSetting: (state, action: PayloadAction<IDatabaseSetting>): ISettingsSliceState => {
			return { ...state, databaseSetting: action.payload };
		}, // init from localStorage
		reset: (): ISettingsSliceState => initialState,
	},
});

export const actions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
