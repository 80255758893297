import { Button, Card, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks } from 'app/store/slices/session';
import { IRequestSessionData } from 'app/store/slices/session/type';
import './styles.css';
export const LoginPage = () => {
	const isLoading = useAppSelector(state => state.session.isLoading);
	const dispatch = useAppDispatch();
	const onFinish = (values: IRequestSessionData) => {
		dispatch(thunks.getSession(values));
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<Card
			title='Вход в систему '
			size='small'
			type='inner'
			className='card-container'
			headStyle={{ background: '#509EE3', color: '#FFFFFF' }}
			loading={isLoading}
		>
			<Form
				name='basic'
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete='off'
			>
				<Form.Item
					label='Электронная почта'
					name='username'
					rules={[{ required: true, message: 'Пожалуйста, введите электронную почту' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label='Пароль'
					name='password'
					rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 18 }}>
					<Button htmlType='submit'>Войти</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};
