import { Card, List } from 'antd';
import './styles.css';
type Props = {
	children: JSX.Element | JSX.Element[];
	cardTitle?: JSX.Element | string;
};

export const ListItem = ({ children, cardTitle }: Props) => {
	return (
		<List.Item>
			<Card title={cardTitle} className='list-item'>
				{children}
			</Card>
		</List.Item>
	);
};
