import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { actions } from './database';
import * as api from './api';
import { IResponseDatabase, IResponseTables } from './type';
import { RootState } from 'app/store/store';

export const getDatabases = createAsyncThunk<void>(`api/get/database`, async (_, { dispatch }) => {
	try {
		const data: IResponseDatabase = await api.getDatabases();
		dispatch(actions.setDataBase(data));
	} catch (e: any) {
		message.error(`${e}`);
	}
});

export const getSchemas = createAsyncThunk<void>(
	`api/get/database/{id}/schemas`,
	async (_, { dispatch, getState }) => {
		const { settings } = getState() as RootState;
		try {
			if (settings.databaseSetting.db) {
				const data: string[] = await api.getSchemasByDatabaseId(settings.databaseSetting.db);
				dispatch(actions.setSchemas(data));
			} else {
				throw 'Не выбрана база данных';
			}
		} catch (e: any) {
			message.error(`${e}`);
		}
	},
);

export const getTables = createAsyncThunk<void>(
	`api/get/database/{db_id}/schema/{schema_id}`,
	async (_, { dispatch, getState }) => {
		const { settings } = getState() as RootState;
		try {
			if (settings.databaseSetting.db && settings.databaseSetting.schema) {
				const data: IResponseTables[] = await api.getTablesBySchema(
					settings.databaseSetting.db,
					settings.databaseSetting.schema,
				);
				const fEvent = data.find(item => item.name === 'events_map');
				if (fEvent) {
					localStorage.setItem('filterTableId', `${fEvent.id}`);
				}
				dispatch(actions.setTables(data));
			} else {
				throw 'Не выбрана база данных и/или схема';
			}
		} catch (e: any) {
			message.error(`${e}`);
		}
	},
);
