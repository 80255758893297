import axios from 'axios';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import { IRequestDataset } from './type';

export const getDataset = async (values: IRequestDataset): Promise<IResponseTypeSqlQuery> => {
	return axios
		.post('api/dataset', values)
		.then(function (response) {
			return response.data as IResponseTypeSqlQuery;
		})
		.catch(function (error) {
			throw error;
		});
};
