import { configureStore } from '@reduxjs/toolkit';
import { collectionReducer } from './slices/collection';
import { databaseReducer } from './slices/database';
import { datasetReducer } from './slices/dataset';
import { eventReducer } from './slices/events';
import { sessionReducer } from './slices/session';
import { settingsReducer } from './slices/settings';
import { sqlQueryReducer } from './slices/sql_query';

export const store = configureStore({
	reducer: {
		sqlQuery: sqlQueryReducer,
		collection: collectionReducer,
		database: databaseReducer,
		settings: settingsReducer,
		dataset: datasetReducer,
		session: sessionReducer,
		event: eventReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
