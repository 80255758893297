import { IResponseTables } from "../database/type";
import { IPossibleValuesParams } from "./type";

export const getQueryForFilterPossibleValuesDataset = (params: IPossibleValuesParams, tables: IResponseTables[]) => {
    const columnFieldId = tables.find(table => table.id === params.tableId)
    ?.fields?.fields.find(field => field.name === 'column')?.id;
const valueFieldId = tables
    .find(table => table.id === params.tableId)
    ?.fields?.fields.find(field => field.name === 'value')?.id;

    return {
        database: Number(params.dbId),
        query: {
            'source-table': params.tableId,
            limit: 50,
            'order-by': [
                [
                  "asc",
                  [
                    "field",
                    valueFieldId,
                    null
                  ]
                ]
              ],
            filter: [
                'and',
                ['=', ['field', columnFieldId, null], params.fieldName],
                [
                    'starts-with',
                    ['field', valueFieldId, null],
                    params.newValue,
                    {
                        'case-sensitive': false,
                    },
                ],
            ],
        },
        type: 'query',
    }    
}


export const getQueryForEventPropertiesPossibleValuesDataset = (params: IPossibleValuesParams, tables: IResponseTables[]) => {
    
    const fieldName = getPropertyName(params.fieldName)
    const eventTypeFieldId = tables.find(table => table.id === params.tableId)
    ?.fields?.fields.find(field => field.name === 'event_type')?.id;
    const eventPropertyFieldId = tables.find(table => table.id === params.tableId)
    ?.fields?.fields.find(field => field.name === 'event_property')?.id;
    const valueFieldId = tables
    .find(table => table.id === params.tableId)
    ?.fields?.fields.find(field => field.name === 'value')?.id;

    return {
        database: Number(params.dbId),
        query: {
            'source-table': params.tableId,
            limit: 50,
            'order-by': [
                [
                  "asc",
                  [
                    "field",
                    valueFieldId,
                    null
                  ]
                ]
              ],
            filter: [
                'and',
                ['=', ['field', eventTypeFieldId, null], params.eventType],
                ['=', ['field', eventPropertyFieldId, null], fieldName],
                [
                    'starts-with',
                    ['field', valueFieldId, null],
                    params.newValue,
                    {
                        'case-sensitive': false,
                    },
                ],
            ],
        },
        type: 'query',
    }    
}



const getPropertyName = (input: string): string => {
    if (input.startsWith("event_properties['")) {
      // Обработка строки вида event_properties['deliveryMode']
      const start = input.indexOf("['");
      const end = input.lastIndexOf("']");
      const propertyName = input.substring(start + 2, end);
      return propertyName;
    } else if (input.startsWith("event_properties__")) {
      // Обработка строки вида event_properties__deliveryMode
      const start = input.indexOf("__");
      const propertyName = input.substring(start + 2);
      return propertyName;
    } else {
      return input;
    }
  }