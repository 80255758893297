import axios from 'axios';
import { IResponseCollectionData, IResponseCollectionItems } from './type';

export const getCollections = async (): Promise<IResponseCollectionData[]> => {
	return axios
		.get('api/collection/tree')
		.then(function (response) {
			return response.data as IResponseCollectionData[];
		})
		.catch(function (error) {
			throw error;
		});
};

export const getCollectionItems = async (
	collectionId: number,
): Promise<IResponseCollectionItems> => {
	return axios
		.get(`api/collection/${collectionId}/items`)
		.then(function (response) {
			return response.data as IResponseCollectionItems;
		})
		.catch(function (error) {
			throw error;
		});
};
