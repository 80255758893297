import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { IResponseDatabase, IResponseTables } from './type';
interface IDatabaseSliceState {
	isLoading: boolean;
	data: IResponseDatabase;
	schemas: string[];
	tables: IResponseTables[];
}

const initialState: IDatabaseSliceState = {
	isLoading: false,
	schemas: [],
	tables: [],
	data: {
		total: null,
		data: [],
	},
};

export const databaseSlice = createSlice({
	name: 'databaseSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): IDatabaseSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setDataBase: (state, action: PayloadAction<IResponseDatabase>): IDatabaseSliceState => ({
			...state,
			data: action.payload,
		}),
		setTables: (state, action: PayloadAction<IResponseTables[]>): IDatabaseSliceState => ({
			...state,
			tables: action.payload,
		}),
		setSchemas: (state, action: PayloadAction<string[]>): IDatabaseSliceState => ({
			...state,
			schemas: action.payload,
		}),

		reset: (): IDatabaseSliceState => initialState,
	},
});

export const actions = databaseSlice.actions;
export const databaseReducer = databaseSlice.reducer;
export { thunks };
