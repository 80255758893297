import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { IResponseCollectionData, IResponseCollectionItems } from './type';
interface ICollectionSliceState {
	isLoading: boolean;
	data: IResponseCollectionData[];
	items: IResponseCollectionItems;
}

const initialState: ICollectionSliceState = {
	isLoading: false,
	data: [],
	items: {
		total: null,
		data: [],
	},
};

export const collectionSlice = createSlice({
	name: 'collectionSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): ICollectionSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setData: (state, action: PayloadAction<IResponseCollectionData[]>): ICollectionSliceState => ({
			...state,
			data: action.payload,
		}),
		setItems: (state, action: PayloadAction<IResponseCollectionItems>): ICollectionSliceState => ({
			...state,
			items: action.payload,
		}),

		reset: (): ICollectionSliceState => initialState,
	},
});

export const actions = collectionSlice.actions;
export const collectionReducer = collectionSlice.reducer;
export { thunks };
