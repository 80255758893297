import { useCallback, useState, useEffect } from 'react';
import { Button, Drawer, Input, message, Select, Space, Spin, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks as datasetThunks } from 'app/store/slices/dataset';
import { debounce } from 'lodash';
import { getEventsPropertiesOptionsFromData, getOperatorOptions, getOptionsParamsFromData, getPossibleValuesOptionsFromData } from 'app/utils/fieldUtils';
import {
	FilterType,
	FilterTypes,
	FilterOperators,
	PossibleValuesTableTypes,
} from 'app/pages/HomePage/components/FilterDrawer/types';
import './styles.css';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import { IDatabaseSetting } from 'app/store/slices/settings/type';

type Props = {
	handleClose: () => void;
	handleSubmit: (values: FilterType[], id: string) => void;
	id: string;
	openNumber: string | null;
	defaultFilters: FilterType[];
	eventType: string | null;
};

export const AddParamsDrawer = ({
	handleClose,
	handleSubmit,
	id,
	openNumber,
	defaultFilters,
	eventType,
}: Props) => {
	const dispatch = useAppDispatch();
	const [stateWhere, setStateWhere] = useState<FilterType[]>([]);
	const dataEvents = useAppSelector(state => state.dataset.data);
	const dbSettings = JSON.parse(localStorage.getItem('settingDatabase') || 'null') as IDatabaseSetting;


	
	useEffect(() => {
		if (defaultFilters.length) {
			setStateWhere(defaultFilters);
		}
	}, []);
	const callbacks = {
		handleCreateWhere: useCallback(() => {
			setStateWhere([
				...stateWhere,
				{
					id: `${stateWhere.length + 1}`,
					name: '',
					value: '',
					type: FilterTypes.Text,
					operator: FilterOperators.Is,
					possibleValues: null
				},
			]);
		}, [stateWhere]),
		handleRemoveWhere: useCallback(
			(id: string) => {
				setStateWhere(stateWhere.filter(item => item.id !== id));
			},
			[stateWhere],
		),
		handleParamChange: useCallback(
			(value: string, eventId: string) => {
				const index = stateWhere.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...stateWhere];
					newEvents[index] = { ...stateWhere[index], name: value };
					setStateWhere(newEvents);
				} else {
					message.error('Параметр не найден');
				}
			},
			[stateWhere],
		),
		handleValueChange: useCallback(
			(event: any, eventId: string) => {
				const index = stateWhere.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...stateWhere];
					newEvents[index] = { ...stateWhere[index], value: event.target.value };
					setStateWhere(newEvents);
				} else {
					message.error('Параметр не найден');
				}
			},
			[stateWhere],
		),
		handlePossibleValuesChange: useCallback(
			(data: IResponseTypeSqlQuery | null, id: string) => {
				const index = stateWhere.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateWhere];
					newFilters[index] = { ...stateWhere[index], possibleValues: data, };
					setStateWhere(newFilters);
				}
			},
			[stateWhere],
		),
		handleSelectValueChange: useCallback(
			(value: any, eventId: string) => {
				const index = stateWhere.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...stateWhere];
					newEvents[index] = { ...stateWhere[index], value: value };
					setStateWhere(newEvents);
				} else {
					message.error('Параметр не найден');
				}
			},
			[stateWhere],
		),
		handleOperatorChange: useCallback(
			(value: FilterOperators, id: string) => {
				const index = stateWhere.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateWhere];
					newFilters[index] = { ...stateWhere[index], operator: value };
					setStateWhere(newFilters);
				}
			},
			[stateWhere],
		),
		handleSubmit: useCallback(() => {
			handleSubmit(stateWhere, id);
			handleClose();
		}, [stateWhere]),
		handleSearch: useCallback((newValue: string, fieldName: any, id: string) => {
			dispatch(
				datasetThunks.getPossibleValues({
					id: id,
					dbId: Number(dbSettings.db),
					tableId: Number(dbSettings.tableEventPropertiesPossibleValues),
					tableType: PossibleValuesTableTypes.EventProperty,
					fieldName: fieldName,
					newValue: newValue,
					eventType: eventType,
				}),
			).unwrap().then(data => callbacks.handlePossibleValuesChange(data, id));
			callbacks.handlePossibleValuesChange(null, id)
		}, [stateWhere]),
	};

	return (
		<Drawer
			title='Параметры'
			width={700}
			onClose={handleClose}
			open={openNumber === id}
			extra={
				<Space>
					<Button onClick={handleClose}>Отмена</Button>
					<Button type='primary' onClick={callbacks.handleSubmit}>
						Сохранить
					</Button>
				</Space>
			}
		>
			<div className='container__select-field-param'>
				{stateWhere.map(item => (
					<div className='field-container' key={item.id}>
						<Select
							className='select-field-param'
							showSearch
							placeholder='Выберите параметр'
							optionFilterProp='children'
							onChange={value => callbacks.handleParamChange(value, item.id)}
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							options={getOptionsParamsFromData(dataEvents, eventType) || []}
						/>
						<Select
							className='select-field-operator'
							placeholder='Выберите оператор'
							defaultValue={FilterOperators.Is}
							onChange={value => callbacks.handleOperatorChange(value, item.id)}
							options={getOperatorOptions(item)}
						/>
						<Select
								showSearch
								className='input-field-param'
								placeholder='Введите значение'
								onSearch={value => callbacks.handleSearch(value, item.name, item.id)}
								onFocus={() => callbacks.handleSearch("", item.name, item.id)}
								onChange={debounce(event => callbacks.handleSelectValueChange(event, item.id), 300)}
								notFoundContent={<Spin size="small" />}
								options={getEventsPropertiesOptionsFromData(item.possibleValues ? item.possibleValues : null) || []}
							/>
						<Button
							className='remove-action-with-field'
							title='Удалить условие'
							onClick={() => callbacks.handleRemoveWhere(item.id)}
							icon={<DeleteOutlined />}
						/>
					</div>
				))}
			</div>
			{eventType ? (
				<Button onClick={callbacks.handleCreateWhere}>Добавить</Button>
			) : (
				<Typography.Text type='warning'>
					Для добавления параметров необходимо выбрать событие
				</Typography.Text>
			)}
		</Drawer>
	);
};
