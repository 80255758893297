import axios from 'axios';
import { IResponseSessionData, IResponseUserData, IRequestSessionData } from './type';

export const getSession = async (values: IRequestSessionData): Promise<IResponseSessionData> => {
	return axios
		.post('api/session', values)
		.then(function (response) {
			return response.data as IResponseSessionData;
		})
		.catch(function (error) {
			throw error;
		});
};

export const getUser = async (): Promise<IResponseUserData> => {
	return axios
		.get('api/user/current')
		.then(function (response) {
			return response.data as IResponseUserData;
		})
		.catch(function (error) {
			throw error;
		});
};

export const removeSession = async (): Promise<IResponseSessionData> => {
	return axios
		.delete('api/session')
		.then(function (response) {
			return response.data as IResponseSessionData;
		})
		.catch(function (error) {
			throw error;
		});
};
