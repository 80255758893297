import { Divider, Typography } from 'antd';
import { LabelField } from 'app/components/LabelField';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks } from 'app/store/slices/database';
import { IResponseDatabaseData, IResponseTables } from 'app/store/slices/database/type';
import { getOptions } from 'app/utils/fieldUtils';
import { useCallback } from 'react';

type Props = {
	handleChange: (value: string, option: string) => void;
};

export const SettingContent = ({ handleChange }: Props) => {
	const { data, schemas, tables, isLoading } = useAppSelector(state => state.database);
	const {
		databaseSetting: {
			db,
			schema,
			tableEvents,
			tableColumnPossibleValues,
			tableEventPropertiesPossibleValues,
		},
	} = useAppSelector(state => state.settings);
	const dispatch = useAppDispatch();
	const callbacks = {
		getSchemas: useCallback(() => {
			dispatch(thunks.getSchemas());
		}, []),
		getTables: useCallback(() => {
			dispatch(thunks.getTables());
		}, []),
	};
	return (
		<div className='content-container'>
			<Typography.Title level={5}>Источник выбора событий:</Typography.Title>
			<LabelField
				label='База данных'
				name='db'
				options={getOptions<IResponseDatabaseData>(data.data, item => ({
					value: `${item.id}`,
					label: item.name,
				}))}
				onChange={handleChange}
				isLoading={isLoading}
				value={db}
			/>
			<LabelField
				label='Схема'
				name='schema'
				options={getOptions<string>(schemas, item => ({
					value: item,
					label: item,
				}))}
				onChange={handleChange}
				isLoading={isLoading}
				getData={callbacks.getSchemas}
				fetchData={db}
				value={schema}
			/>
			<LabelField
				label='Таблица событий'
				name='tableEvents'
				options={getOptions<IResponseTables>(tables, item => ({
					value: `${item.id}`,
					label: item.display_name,
				}))}
				onChange={handleChange}
				isLoading={isLoading}
				getData={callbacks.getTables}
				fetchData={schema}
				value={tableEvents}
			/>
			<LabelField
				label='Таблица возможных значений фильтров'
				name='tableColumnPossibleValues'
				options={getOptions<IResponseTables>(tables, item => ({
					value: `${item.id}`,
					label: item.display_name,
				}))}
				onChange={handleChange}
				isLoading={isLoading}
				getData={callbacks.getTables}
				fetchData={schema}
				value={tableColumnPossibleValues}
			/>
			<LabelField
				label='Таблица возможных значений свойств событий'
				name='tableEventPropertiesPossibleValues'
				options={getOptions<IResponseTables>(tables, item => ({
					value: `${item.id}`,
					label: item.display_name,
				}))}
				onChange={handleChange}
				isLoading={isLoading}
				getData={callbacks.getTables}
				fetchData={schema}
				value={tableEventPropertiesPossibleValues}
			/>
			<Divider />
		</div>
	);
};
