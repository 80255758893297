import axios from 'axios';
import { IResponseDatabase, IResponseTables } from './type';

export const getDatabases = async (): Promise<IResponseDatabase> => {
	return axios
		.get('api/database')
		.then(function (response) {
			return response.data as IResponseDatabase;
		})
		.catch(function (error) {
			throw error;
		});
};

export const getSchemasByDatabaseId = async (databaseId: string): Promise<string[]> => {
	return axios
		.get(`api/database/${databaseId}/schemas`)
		.then(function (response) {
			return response.data as string[];
		})
		.catch(function (error) {
			throw error;
		});
};

export const getTablesBySchema = async (
	databaseId: string,
	schemaKey: string,
): Promise<IResponseTables[]> => {
	try {
		const response = await axios.get(`api/database/${databaseId}/schema/${schemaKey}`);
		const data = response.data as IResponseTables[];

		const updatedData = await Promise.all(
			data.map(async item => {
				try {
					const additionalResponse = await axios.get(`api/table/${item.id}/query_metadata`);
					const additionalData = additionalResponse.data;
					return {
						...item,
						fields: additionalData,
					};
				} catch (error) {
					throw new Error(`Error fetching additional data for item with id: ${item.id}`);
				}
			}),
		);

		return updatedData;
	} catch (error) {
		throw new Error('Error fetching tables by schema');
	}
};
