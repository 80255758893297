import React, { useCallback, useState } from 'react';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, message, Select } from 'antd';
import { AddParamsDrawer } from 'app/pages/HomePage/components/AddParamsDrawer';
import { FilterType } from 'app/pages/HomePage/components/FilterDrawer/types';
import { ListItem } from 'app/pages/HomePage/components/ListItem';
import { useAppSelector } from 'app/store';
import { EventType } from 'app/pages/HomePage/components/EventList/types';
import { getOptionsEventsFromData } from 'app/utils/fieldUtils';
import './styles.css';

type Props = {
	setEvents: (value: EventType[]) => void;
	item: EventType;
	events: EventType[];
};

export const EventCard = React.memo(({ item, setEvents, events }: Props) => {
	const [stateOpenNumber, setStateOpenNumber] = useState<string | null>(null);
	const dataEvents = useAppSelector(state => state.dataset.data);
	const eventValue = events.find(event => item.id === event.id)?.value;
	const callbacks = {
		handleRemoveEvent: useCallback(
			(id: string) => {
				setEvents(events.filter(item => item.id !== id));
			},
			[events],
		),
		handleClose: useCallback(() => {
			setStateOpenNumber(null);
		}, [stateOpenNumber]),
		handleOpen: useCallback(
			(id: string) => {
				setStateOpenNumber(id);
			},
			[stateOpenNumber],
		),
		handleWhereSubmit: useCallback(
			(filters: FilterType[], eventId: string) => {
				const index = events.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...events];
					newEvents[index] = { ...events[index], filters: filters };
					setEvents(newEvents);
				} else {
					message.error('Событие не найдено');
				}
			},
			[events],
		),
		handleValueChange: useCallback(
			(value: string, eventId: string) => {
				const index = events.findIndex(item => item.id === eventId);
				if (index + 1) {
					const newEvents = [...events];
					newEvents[index] = { ...events[index], value: value };
					setEvents(newEvents);
				} else {
					message.error('Событие не найдено');
				}
			},
			[events],
		),
	};
	return (
		<ListItem
			key={item.id}
			cardTitle={
				<Select
					allowClear
					className='select-field'
					showSearch
					placeholder='Выберите событие'
					optionFilterProp='children'
					onChange={value => callbacks.handleValueChange(value, item.id)}
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					}
					options={getOptionsEventsFromData(dataEvents?.data.rows || null) || []}
					value={eventValue}
				/>
			}
		>
			<div className='action-container'>
				{eventValue && (
					<>
						<Button
							title='Добавить условие'
							icon={<PlusSquareOutlined />}
							onClick={() => callbacks.handleOpen(item.id)}
						/>
						<AddParamsDrawer
							id={item.id}
							eventType={eventValue}
							handleClose={callbacks.handleClose}
							handleSubmit={callbacks.handleWhereSubmit}
							openNumber={stateOpenNumber}
							defaultFilters={events.find(event => item.id === event.id)?.filters || []}
						/>
					</>
				)}
				<Button
					onClick={() => callbacks.handleRemoveEvent(item.id)}
					title='Удалить событие'
					icon={<DeleteOutlined />}
				/>
			</div>
		</ListItem>
	);
});
