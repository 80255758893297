import { useCallback, useState } from 'react';
import { Button, Drawer, Space, Table } from 'antd';
import ReactCodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from 'app/store';

import { actions, thunks } from 'app/store/slices/sql_query';
import './styles.css';

type Props = {
	activeTab: string;
};

const ACTIONS = [
	{
		id: 'visualization',
		title: 'Визуализация',
	},
	{
		id: 'sqlQuery',
		title: 'Посмотреть SQL-Запрос',
	},
];

export const SimpleVisualization = ({}: Props) => {
	const { data, sqlQuery, visualizationStateOpenId } = useAppSelector(state => state.sqlQuery);
	const dispatch = useAppDispatch();
	const callbacks = {
		handleClose: useCallback((): void => {
			dispatch(actions.setVisualizationStateOpenId(null));
		}, []),
		handleOpen: useCallback((id: string): void => {
			dispatch(actions.setVisualizationStateOpenId(id));

		}, []),
		handleSubmit: useCallback(async () => {
			await dispatch(thunks.executeSqlQuery());
			dispatch(actions.setVisualizationStateOpenId('visualization'));
		}, []),
		handleChangeSqlQuery: useCallback((value: string): void => {
			dispatch(actions.setSqlQuery(value));
		}, []),
	};

	function renderColumns() {
		return data?.data.cols.map(col => ({
			title: col.display_name,
			dataIndex: col.name,
			key: col.name,
		}));
	}

	function renderData() {
		return data?.data.rows.map(row => {
			return row.reduce<any>((acc, item, index) => {
				acc[data?.data.cols[index].name] = item === null ? '-' : `${item}`;
				return acc;
			}, {});
		});
	}

	function renderDrawerContent() {
		switch (visualizationStateOpenId) {
			case 'visualization':
				return <Table columns={renderColumns()} dataSource={renderData()} />;
			case 'sqlQuery':
				return (
					<ReactCodeMirror
						value={sqlQuery}
						extensions={[sql()]}
						onChange={debounce(callbacks.handleChangeSqlQuery, 500)}
					/>
				);
		}
	}
	return (
		<div className='container-action'>
			<Drawer
				title={ACTIONS.find(item => item.id === visualizationStateOpenId)?.title}
				placement={'bottom'}
				height={visualizationStateOpenId === 'sqlQuery' ? '100%' : '50%'}
				onClose={callbacks.handleClose}
				open={Boolean(visualizationStateOpenId)}
				extra={
					visualizationStateOpenId === 'sqlQuery' && (
						<Space>
							<Button type='primary' onClick={callbacks.handleSubmit}>
								Выполнить запрос
							</Button>
						</Space>
					)
				}
			>
				{renderDrawerContent()}
			</Drawer>
			{ACTIONS.map(item => {
				return (
					<Button type='text' key={item.id} onClick={() => callbacks.handleOpen(item.id)}>
						{item.title}
					</Button>
				);
			})}
		</div>
	);
};
