import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks } from 'app/store/slices/dataset';
import { actions } from 'app/store/slices/settings';
import { useCallback, useState } from 'react';
import { SettingContent } from './SettingContent';

export const SettingDrawer = () => {
	const dispatch = useAppDispatch();
	const databaseSetting = useAppSelector(state => state.settings.databaseSetting);
	const [stateIsOpen, setStateIsOpen] = useState<boolean>(false);
	const callbacks = {
		handleClose: useCallback(() => {
			setStateIsOpen(false);
		}, []),
		handleOpen: useCallback(() => {
			setStateIsOpen(true);
		}, []),
		handleSubmit: useCallback(() => {
			localStorage.setItem('settingDatabase', JSON.stringify(databaseSetting));
			dispatch(thunks.changeDataset(databaseSetting));
			dispatch(thunks.getFilters());
			callbacks.handleClose();
		}, [databaseSetting]),
		handleChange: useCallback((value: string, option: string) => {
			dispatch(
				actions.changeField({
					settingKey: 'databaseSetting',
					fieldKey: option,
					valueFieldKey: value,
				}),
			);
		}, []),
	};
	return (
		<>
			<Button
				onClick={callbacks.handleOpen}
				shape='circle'
				type='text'
				icon={<SettingOutlined style={{ fontSize: 24, color: '#fff' }} />}
			/>
			<Drawer
				title='Настройки'
				placement={'right'}
				width={700}
				onClose={callbacks.handleClose}
				open={stateIsOpen}
				extra={
					<Space>
						<Button type='primary' onClick={callbacks.handleSubmit}>
							Сохранить
						</Button>
					</Space>
				}
			>
				<SettingContent handleChange={callbacks.handleChange} />
			</Drawer>
		</>
	);
};
