import _ from 'lodash';
import {
	ICol,
	IResponseTypeSqlQuery,
	IRow,
	FunnelGranularityTypes,
} from 'app/store/slices/sql_query/type';
import {
	FilterOperators,
	FilterType,
	FilterTypes,
} from 'app/pages/HomePage/components/FilterDrawer/types';

import { message } from 'antd';

type Options = { label: string; value: string };

export const getOptionsEventsFromData = (dataEvents: IRow[] | null): Options[] | void => {
	if (dataEvents) {
		const options: Options[] = [];
		_.uniqBy(dataEvents, item => item[0]).forEach(element => {
			options.push({ label: `${element[0]}`, value: `${element[0]}` });
		});
		return options;
	}
};

export const getOptionsFromColumns = (dataEvents?: ICol[]): Options[] | void => {
	if (dataEvents) {
		const options: Options[] = [];
		_.uniqBy(dataEvents, item => item.name).forEach(element => {
			options.push({
				label: `${element.display_name}`,
				value: `${element.name}-${element.base_type}`,
			});
		});
		return options;
	}
};

export const getOperatorOptions = (item: FilterType): Options[] => {
	const options: Options[] = [
		{ value: FilterOperators.Is, label: '[=] Равно' },
		{ value: FilterOperators.IsNot, label: '[!=] Не равно' },
	];
	switch (item.type) {
		case FilterTypes.Text:
			options.push(
				{ value: FilterOperators.Contains, label: '[⊃] Содержит' },
				{ value: FilterOperators.NotContains, label: '[!⊃] Не содержит' },
			);
			return options;
		case FilterTypes.Float:
		case FilterTypes.BigInteger:
		case FilterTypes.Integer:
			options.push(
				{ value: FilterOperators.LessOrEqual, label: '[<] Меньше чем' },
				{ value: FilterOperators.Less, label: '[<=] Меньше чем или равно' },
				{ value: FilterOperators.Greater, label: '[>] Больше чем' },
				{ value: FilterOperators.GreaterOrEqual, label: '[>=] Больше чем или равно' },
			);
			return options;
		default:
			return options;
	}
};

export const getGroupGranularityOptions = (): Options[] => {
	const options: Options[] = [
		{ value: 'toHour', label: 'Час' },
		{ value: 'toDate', label: 'День' },
		{ value: 'toWeek', label: 'Неделя' },
		{ value: 'toMonth', label: 'Месяц' },
		{ value: 'toQuarter', label: 'Квартал' },
		{ value: 'toYear', label: 'Год' },
	];

	return options;
};

export const getFunnelGranularityOptions = (): Options[] => {
	const options: Options[] = [
		{ value: FunnelGranularityTypes.Days, label: 'Дни' },
		{ value: FunnelGranularityTypes.Hours, label: 'Часы' },
		{ value: FunnelGranularityTypes.Minutes, label: 'Минуты' },
		{ value: FunnelGranularityTypes.Seconds, label: 'Секунды' },
	];
	return options;
};

export const getOptionsParamsFromData = (
	dataEvents: IResponseTypeSqlQuery | null,
	eventType: string | null,
): Options[] | void => {
	if (dataEvents && eventType) {
		const options: Options[] = [];
		const substringToRemove = 'event_properties__';
		dataEvents.data.rows
			.filter(item => item[0] === eventType)
			.forEach(element => {
				let label = element[1];
				let value = element[1] as string;
				const hasColumn = element[2] || element[2] == null;
				if (typeof label === 'string') {
					label = label.replace(substringToRemove, '');
				}
				if (!hasColumn) {
					const property = value.split('__')[1];
					value = `event_properties['${property}']`;
				}
				options.push({ label: `${label}`, value: `${value}` });
			});
		return options;
	}
	message.error(
		'Отсутствуют данные для заполнения выпадающего списка, пожалуйста, проверьте заполненость настроек, или обратитесь к администратору',
	);
};

export const getPossibleValuesOptionsFromData = (
	data: IResponseTypeSqlQuery | null,
): Options[] | void => {
	if (data) {
		const options: Options[] = [];
		data.data.rows.forEach(element => {
			let label = element[1];
			let value = element[1] as string;
			options.push({ label: `${label}`, value: `${value}` });
		});
		return options;
	}
};

export const getEventsPropertiesOptionsFromData = (
	data: IResponseTypeSqlQuery | null,
): Options[] | void => {
	if (data) {
		const options: Options[] = [];
		data.data.rows.forEach(element => {
			let label = element[2];
			let value = element[2] as string;
			options.push({ label: `${label}`, value: `${value}` });
		});
		return options;
	}
};

export const getOptions = <T>(
	data: T[],
	serialize: (item: T) => { value: string; label: string },
) => {
	return data.map(serialize);
};
