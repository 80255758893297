import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { actions } from './session';
import * as api from './api';
import { IResponseSessionData, IResponseUserData, IRequestSessionData } from './type';

export const getSession = createAsyncThunk<void, IRequestSessionData>(
	`api/get/session`,
	async (values, { dispatch }) => {
		try {
			dispatch(actions.setIsLoading(true));
			const data: IResponseSessionData = await api.getSession(values);
			dispatch(actions.setSession(data));
			await dispatch(getUser());
			document.cookie = `X-Metabase-Session=${data.id}`;
		} catch (e: any) {
			message.error(`${e}`);
		} finally {
			dispatch(actions.setIsLoading(false));
		}
	},
);

export const getUser = createAsyncThunk<void>(`api/get/session/user`, async (_, { dispatch }) => {
	try {
		dispatch(actions.setIsLoading(true));
		const data: IResponseUserData = await api.getUser();
		dispatch(actions.setUser(data));
	} catch (e: any) {
		dispatch(actions.setError(e.toString()));
	} finally {
		dispatch(actions.setIsLoading(false));
	}
});

export const removeSession = createAsyncThunk<void>(
	`api/get/session/user`,
	async (_, { dispatch }) => {
		try {
			dispatch(actions.setIsLoading(true));
			await api.removeSession();
			dispatch(actions.reset());
			document.cookie = `X-Metabase-Session=${null}`;
		} catch (e: any) {
			message.error(`${e}`);
		} finally {
			dispatch(actions.setIsLoading(false));
		}
	},
);
