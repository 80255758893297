import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { actions } from './collection';
import * as api from './api';
import { IResponseCollectionData, IResponseCollectionItems } from './type';

export const getCollections = createAsyncThunk<void>(
	`api/get/collection/tree`,
	async (_, { dispatch }) => {
		try {
			const data: IResponseCollectionData[] = await api.getCollections();
			dispatch(actions.setData(data));
		} catch (e: any) {
			message.error(`${e}`);
		}
	},
);

export const getCollectionItems = createAsyncThunk<void, number>(
	`api/get/collection/collectionId/items`,
	async (collectionId, { dispatch }) => {
		try {
			const data: IResponseCollectionItems = await api.getCollectionItems(collectionId);
			dispatch(actions.setItems(data));
		} catch (e: any) {
			message.error(`${e}`);
		}
	},
);
