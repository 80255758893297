import { useCallback, useEffect, useState } from 'react';
import { Layout, theme, Typography, Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks as collectionThunks } from 'app/store/slices/collection';
import { thunks as databaseThunks } from 'app/store/slices/database';
import { thunks as sessionThunks } from 'app/store/slices/session';
import { thunks as datasetThunks } from 'app/store/slices/dataset';
import { EventList } from './components/EventList';
import { SimpleVisualization } from './components/SimpleVisualization';
import { SettingDrawer } from './components/SettingDrawer/SettingDrawer';
import { actions } from 'app/store/slices/sql_query';
import { generateSqlQuery } from 'app/store/slices/sql_query/utils';
import './styles.css';

export const HomePage = () => {
	const dispatch = useAppDispatch();
	const [stateActiveTab, setStateActiveTab] = useState<string>('1');
	const { events, funnel, filters, groupby, eventsOrder } = useAppSelector(state => state.event);
	const toSave = false;

	const callbacks = {
		handleChange: useCallback(
			(key: string) => {
				setStateActiveTab(key);
			},
			[stateActiveTab],
		),
		handleLogout: useCallback(() => {
			dispatch(sessionThunks.removeSession());
		}, []),
	};

	const {
		token: { colorBgContainer },
	} = theme.useToken();

	useEffect(() => {
		dispatch(collectionThunks.getCollections());
		dispatch(databaseThunks.getDatabases());
		dispatch(datasetThunks.initDataset());
		dispatch(datasetThunks.getFilters());
		dispatch(
			actions.setSqlQuery(generateSqlQuery(events, funnel, filters, groupby, eventsOrder, toSave)),
		);
	}, []);

	return (
		<Layout className='layout'>
			<Layout.Header className='header'>
				<Typography.Title style={{ color: '#fff' }} level={3}>
					Генератор SQL-запросов
				</Typography.Title>
				<div className='header-actions'>
					<SettingDrawer />
					<Button onClick={callbacks.handleLogout}>Выход</Button>
				</div>
			</Layout.Header>
			<Layout.Content className='content'>
				<div style={{ background: colorBgContainer }} className='site-layout-content'>
					<EventList />
				</div>
			</Layout.Content>
			<Layout.Footer>
				<SimpleVisualization activeTab={stateActiveTab} />
			</Layout.Footer>
		</Layout>
	);
};
