import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from 'app/pages/LoginPage';
import { HomePage } from './app/pages/HomePage';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks } from 'app/store/slices/session';
import './App.css';

export const App = () => {
	const { user, error } = useAppSelector(state => state.session);
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(thunks.getUser());
		// eslint-disable-next-line
	}, []);

	return (
		<BrowserRouter>
			<div className='App'>
				<Routes>
					<Route path='/' element={user ? <HomePage /> : <Navigate to='/login' replace />} />
					<Route
						path='login'
						element={!user && error !== null ? <LoginPage /> : <Navigate to='/' replace />}
					/>
				</Routes>
			</div>
		</BrowserRouter>
	);
};
