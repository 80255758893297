import { useCallback, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import './styles.css';
import { IRequestSaveValues } from 'app/store/slices/sql_query/type';
import { useAppDispatch, useAppSelector } from 'app/store';
import { saveQuestion } from 'app/store/slices/sql_query/thunks';
type Props = {
	disabled: boolean;
};

export const SaveModalForm = ({ disabled }: Props) => {
	const [stateIsOpen, setStateIsOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const collections = useAppSelector(state => state.collection.data);

	const callbacks = {
		handleOpen: useCallback(() => {
			setStateIsOpen(true);
		}, []),
		handleClose: useCallback(() => {
			setStateIsOpen(false);
		}, []),
		handleSubmit: useCallback((values: IRequestSaveValues) => {
			dispatch(saveQuestion(values));
			callbacks.handleClose();
		}, []),
	};
	return (
		<>
			<Button onClick={callbacks.handleOpen} disabled={disabled} type='primary' className='action'>
				Сохранить в Metabase
			</Button>
			<Modal
				title='Создать запрос'
				open={stateIsOpen}
				footer={null}
				onCancel={callbacks.handleClose}
				destroyOnClose
			>
				<Form
					name='basic'
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={{ remember: true }}
					onFinish={callbacks.handleSubmit}
					autoComplete='off'
				>
					<Form.Item
						label='Название'
						name='name'
						rules={[{ required: true, message: 'Пожалуйста, введите значение' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item label='Описание' name='description'>
						<Input />
					</Form.Item>

					<Form.Item label='Коллекция' name='collectionId'>
						<Select placeholder='Выберите значение' allowClear>
							{collections.map(item => {
								return (
									<Select.Option value={item.id} key={item.id}>
										{item.name}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<div className='button-wrapper'>
						<Button type='primary' htmlType='submit'>
							Сохранить
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
};
