import { useCallback, useState } from 'react';
import { Button, DatePicker, Drawer, Input, Select, Space, Checkbox, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { FilterType, FilterTypes, FilterOperators, PossibleValuesTableTypes } from './types';
import {
	getOptionsFromColumns,
	getOperatorOptions,
	getPossibleValuesOptionsFromData,
} from 'app/utils/fieldUtils';
import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks as datasetThunks } from 'app/store/slices/dataset';
import './styles.css';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import { IDatabaseSetting } from 'app/store/slices/settings/type';

type Props = {
	onSubmit: (value: FilterType[]) => void;
};

export const FilterDrawer = ({ onSubmit }: Props) => {
	const dispatch = useAppDispatch();
	const [stateIsOpen, setStateIsOpen] = useState<boolean>(false);
	const [stateFilter, setStateFilter] = useState<FilterType[]>([]);
	const dbSettings = JSON.parse(localStorage.getItem('settingDatabase') || 'null') as IDatabaseSetting;
	const filters = useAppSelector(state => state.dataset.filters);
	const columnsWithPossibleValues = useAppSelector(
		state => state.dataset.columnPossibleValues,
	)?.data.rows.map(item => item[0]) as string[];
	const callbacks = {
		handleOpen: useCallback(() => {
			setStateIsOpen(true);
		}, []),
		handleClose: useCallback(() => {
			setStateIsOpen(false);
		}, []),
		handleCreateWhere: useCallback(() => {
			setStateFilter([
				...stateFilter,
				{
					id: `${stateFilter.length + 1}`,
					name: '',
					value: '',
					operator: FilterOperators.Is,
					type: FilterTypes.Text,
					possibleValues: null
				},
			]);
		}, [stateFilter]),
		handleRemoveWhere: useCallback(
			(id: string) => {
				setStateFilter(stateFilter.filter(item => item.id !== id));
			},
			[stateFilter],
		),
		handleNameChange: useCallback(
			async (valueWithType: string, id: string) => {
				const [value, type] = valueWithType.split('-') as [string, FilterTypes];
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], name: value, type: type, possibleValues: null };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handlePossibleValuesChange: useCallback(
			(data: IResponseTypeSqlQuery | null, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], possibleValues: data, };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleOperatorChange: useCallback(
			(value: FilterOperators, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], operator: value };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleInputValueChange: useCallback(
			(event: any, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], value: event.target.value };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleSelectValueChange: useCallback(
			(value: string, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], value: value };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handletCheckboxChange: useCallback(
			(event: any, id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], value: event.target.checked };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleDateRangeChange: useCallback(
			(values: string[], id: string) => {
				const index = stateFilter.findIndex(item => item.id === id);
				if (index + 1) {
					const newFilters = [...stateFilter];
					newFilters[index] = { ...stateFilter[index], value: values };
					setStateFilter(newFilters);
				}
			},
			[stateFilter],
		),
		handleSubmit: useCallback(() => {
			onSubmit(stateFilter);
			setStateIsOpen(false);
		}, [stateFilter, onSubmit]),
		handleSearch: useCallback((newValue: string, fieldName: string, id: string) => {
			dispatch(
				datasetThunks.getPossibleValues({
					id: id,
					dbId: Number(dbSettings.db),
					tableId: Number(dbSettings.tableColumnPossibleValues),
					tableType: PossibleValuesTableTypes.Filter,
					fieldName: fieldName,
					newValue: newValue,
				}),
			).unwrap().then(data => callbacks.handlePossibleValuesChange(data, id))
			callbacks.handlePossibleValuesChange(null, id)
		}, [stateFilter]),
	};

	const renderValueInput = (item: FilterType) => {
		switch (item.type) {
			case FilterTypes.Date:
			case FilterTypes.DateTime:
				return (
					<DatePicker.RangePicker
						locale={locale}
						style={{ width: '50%' }}
						onChange={value => {
							if (value) {
								callbacks.handleDateRangeChange(
									value.map(item => dayjs(item).format('YYYY-MM-DD')),
									item.id,
								);
							}
						}}
					/>
				);
			case FilterTypes.Boolean:
				return (
					<>
						<Checkbox
							style={{ marginLeft: '12px' }}
							onChange={debounce(event => callbacks.handletCheckboxChange(event, item.id), 300)}
							key={item.id}
						/>
					</>
				);
			default:
				if (columnsWithPossibleValues.find(value => value === item.name)) {
					return (
						<>
							<Select
								className='select-field-operator'
								placeholder='Выберите оператор'
								defaultValue={FilterOperators.Is}
								onChange={value => callbacks.handleOperatorChange(value, item.id)}
								options={getOperatorOptions(item)}
							/>
							<Select
								showSearch
								className='input-field-param'
								placeholder='Введите значение'
								loading={Boolean(!item.possibleValues)}
								allowClear
								onFocus={debounce(() => callbacks.handleSearch(item.value ? item.value as string : "", item.name, item.id), 800)}
								onSearch={debounce(value => callbacks.handleSearch(value, item.name, item.id), 800)}
								onClear={() => callbacks.handleSearch("", item.name, item.id)}
								onChange={debounce(event => callbacks.handleSelectValueChange(event, item.id), 300)}
								notFoundContent={<Spin size="small" />}
								options={getPossibleValuesOptionsFromData(item.possibleValues ? item.possibleValues : null) || []}
							/>
						</>
					);
				} else {
					return (
						<>
							<Select
								className='select-field-operator'
								placeholder='Выберите оператор'
								defaultValue={FilterOperators.Is}
								onChange={value => callbacks.handleOperatorChange(value, item.id)}
								options={getOperatorOptions(item)}
							/>
							<Input
								onChange={debounce(event => callbacks.handleInputValueChange(event, item.id), 300)}
								key={item.id}
								className='input-field-param'
								placeholder='Введите значение'
							/>
						</>
					);
				}
		}
	};

	return (
		<>
			<Button onClick={callbacks.handleOpen} className='add-button'>
				Добавить фильтр
			</Button>
			<Drawer
				open={stateIsOpen}
				title='Фильтры'
				onClose={callbacks.handleClose}
				width={700}
				extra={
					<Space>
						<Button type='primary' onClick={callbacks.handleSubmit}>
							Сохранить
						</Button>
					</Space>
				}
			>
				<div className='container__select-field-param'>
					{stateFilter.map(item => {
						return (
							<div className='field-container' key={item.id}>
								<Select
									className='select-field-param'
									showSearch
									placeholder='Выберите параметр'
									optionFilterProp='children'
									notFoundContent={<Spin size="small" />}
									onChange={value => callbacks.handleNameChange(value, item.id)}
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									options={getOptionsFromColumns(filters?.data.cols) || []}
								/>

								{renderValueInput(item)}
								<Button
									className='remove-action-with-field'
									title='Удалить условие'
									onClick={() => callbacks.handleRemoveWhere(item.id)}
									icon={<DeleteOutlined />}
								/>
							</div>
						);
					})}
				</div>
				<Button onClick={callbacks.handleCreateWhere}>Добавить</Button>
			</Drawer>
		</>
	);
};
