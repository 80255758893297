import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { IResponseSessionData, IResponseUserData } from './type';
interface ISessionSliceState {
	isLoading: boolean;
	session: IResponseSessionData | null;
	user: IResponseUserData | null;
	error: string | null;
}

const initialState: ISessionSliceState = {
	isLoading: false,
	session: null,
	user: null,
	error: '',
};

export const sessionSlice = createSlice({
	name: 'sessionSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): ISessionSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setSession: (state, action: PayloadAction<IResponseSessionData>): ISessionSliceState => ({
			...state,
			session: action.payload,
		}),
		setUser: (state, action: PayloadAction<IResponseUserData>): ISessionSliceState => ({
			...state,
			user: action.payload,
			error: null,
		}),
		setError: (state, action: PayloadAction<any>): ISessionSliceState => ({
			...state,
			error: action.payload,
		}),
		reset: (): ISessionSliceState => initialState,
	},
});

export const actions = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
export { thunks };
