import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { RootState } from 'app/store/store';
import { IDatasetSliceState } from 'app/store/slices/dataset/dataset';
import { actions } from './sql_query';
import * as api from './api';
import { IRequestSaveValues, IResponseTypeSqlQuery } from './type';
import { generateSqlQuery } from './utils';
import { FilterType, FilterTypes } from 'app/pages/HomePage/components/FilterDrawer/types';

const _getSqlQuery = createAsyncThunk<void, boolean>(
	`api/get/sqlQuery=`,
	async (toSave, { dispatch, getState }) => {
		try {
			const {
				event: { events, funnel, filters, groupby, eventsOrder },
			} = getState() as RootState;
			const data: string = generateSqlQuery(events, funnel, filters, groupby, eventsOrder, toSave);
			dispatch(actions.setSqlQuery(data));
		} catch (e: any) {
			message.error(`${e}`);
		}
	},
);

export const getSqlQuery = () => {
	return _getSqlQuery(false);
};

export const getSqlQueryForSaveInMetabase = () => {
	return _getSqlQuery(true);
};

export const executeSqlQuery = createAsyncThunk<void>(
	`api/post/sqlQuery`,
	async (_, { dispatch, getState }) => {
		try {
			const { event, sqlQuery } = getState() as RootState;
			dispatch(actions.setIsLoading(true));
			const data: IResponseTypeSqlQuery = await api.executeSqlQuery(sqlQuery.sqlQuery, event.database);
			if (data.error) {
				message.error(`${data.error}`);
			} else {
				dispatch(actions.setData(data));
			}
		} catch (e: any) {
			message.error(`${e}`);
		} finally {
			dispatch(actions.setIsLoading(false));
		}
	},
);

export const saveQuestion = createAsyncThunk<void, IRequestSaveValues>(
	`api/post/card`,
	async (values, { dispatch, getState }) => {
		try {
			dispatch(actions.setIsLoading(true));
			dispatch(getSqlQueryForSaveInMetabase());
			const {
				sqlQuery,
				dataset,
				event: { filters },
			} = getState() as RootState;

			const datePartitionId = getDatePartitionColId(dataset);
			const datePartitionValue = getDatePartitionValue(filters);

			const res = await api.saveQuestion({
				...values,
				database_id: sqlQuery.data?.database_id,
				date_field_id: datePartitionId,
				datePartitionValue: datePartitionValue,
				sqlQuery: sqlQuery.sqlQuery,
			});
			dispatch(actions.setQuestionId(res.id));
			message.success('Запрос успешно сохранен в Metabase');
		} catch (e: any) {
			message.error(`${e}`);
		} finally {
			dispatch(actions.setIsLoading(false));
		}
	},
);

const getDatePartitionColId = (dataset: IDatasetSliceState) => {
	const datasetCols = dataset.filters?.data.cols;
	const datePartitionCol = datasetCols
		? datasetCols.find(item => item.name === 'date_partition')
		: null;
	return datePartitionCol ? datePartitionCol.field_ref[1] : null;
};

const getDatePartitionValue = (filters: FilterType[]): string | null => {
	const filter = filters.find(
		item => item.type === FilterTypes.Date || item.type === FilterTypes.DateTime,
	);
	if (!filter) {
		return null;
	}
	const value = filter.value as [];
	const newValue = value.join('~');
	return newValue;
};
