import { useCallback } from 'react';
import { Button } from 'antd';
import './styles.css';
import { useAppSelector } from 'app/store';

type Props = {
	disabled: boolean;
};

export const ShowQuestionButton = ({ disabled }: Props) => {
	const questionId = useAppSelector(state => state.sqlQuery.questionId);
	// TODO сделать нормально
	const redirectBaseUrl = process.env.REACT_APP_REDIRECT_URL || 'https://metabase.perek.epoch8.co';
	const callbacks = {
		handleSubmit: useCallback(() => {
			const redirectUrl = redirectBaseUrl + '/question/' + questionId;
			window.open(redirectUrl, '_blank');
		}, [questionId]),
	};

	return (
		<>
			<Button onClick={callbacks.handleSubmit} disabled={disabled} type='primary' className='action'>
				Посмотреть в Metabase
			</Button>
		</>
	);
};
