import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResponseTypeSqlQuery } from 'app/store/slices/sql_query/type';
import * as thunks from './thunks';
export interface IDatasetSliceState {
	isLoading: boolean;
	data: IResponseTypeSqlQuery | null;
	filters: IResponseTypeSqlQuery | null;
	columnPossibleValues: IResponseTypeSqlQuery | null;
	eventsPropertiesPossibleValues: IResponseTypeSqlQuery | null;
}

const initialState: IDatasetSliceState = {
	isLoading: false,
	data: null,
	filters: null,
	columnPossibleValues: null,
	eventsPropertiesPossibleValues: null,
};

export const datasetSlice = createSlice({
	name: 'datasetSlice',
	initialState: initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>): IDatasetSliceState => ({
			...state,
			isLoading: action.payload,
		}),
		setData: (state, action: PayloadAction<IResponseTypeSqlQuery>): IDatasetSliceState => ({
			...state,
			data: action.payload,
		}),
		setColumnPossibleValues: (
			state,
			action: PayloadAction<IResponseTypeSqlQuery>,
		): IDatasetSliceState => ({
			...state,
			columnPossibleValues: action.payload,
		}),
		setEventsPropertiesPossibleValues: (
			state,
			action: PayloadAction<IResponseTypeSqlQuery>,
		): IDatasetSliceState => ({
			...state,
			eventsPropertiesPossibleValues: action.payload,
		}),
		setFilters: (state, action: PayloadAction<IResponseTypeSqlQuery>): IDatasetSliceState => ({
			...state,
			filters: action.payload,
		}),

		reset: (): IDatasetSliceState => initialState,
	},
});

export const actions = datasetSlice.actions;
export const datasetReducer = datasetSlice.reducer;
export { thunks };
